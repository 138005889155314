<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-dark-green tw-p-8"
  >
    <div>
      <div>
        <img
          src="@/assets/svgs/invitation/heart.svg"
          alt="Heart"
          class="tw-mx-auto tw-mb-4 tw-w-20 tw-h-20"
        />
        <h2
          class="tw-text-lg tw-font-semibold tw-text-chartreuse tw-mx-auto tw-w-4/6 tw-mb-2"
        >
          Maybe next time!
        </h2>
        <p
          class="tw-mb-0 tw-text-white tw-text-sm tw-leading-22 tw-mx-auto tw-w-4/6"
        >
          If plans change, you can update your response in the trip hub until
          the RSVP deadline.
        </p>

        <v-btn
          rounded
          color="primary"
          x-large
          class="tw-mt-40 tw-mb-6 tw-tracking-normal"
          :to="{ name: 'TripsRoute' }"
          >Go to My Trips</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tripId: this.$route.params.id,
    };
  },
};
</script>
